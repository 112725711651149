import React, { useCallback, useEffect, useState } from 'react';
import {Dispatch} from 'redux';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import SwiperCore, {Pagination, EffectFade, Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/navigation/navigation.scss';

import {IStagedFragments} from 'domain/staged-fragments';
import {IRecommendation} from 'domain/recommendation';

import {IRootReducer} from 'store/reducer';
import {IOrderState} from 'store/modules/order/types';

import OrderInfo from 'application/blocks/order-info';
import Recommendation from 'application/blocks/recommendation';
import Manager from 'application/blocks/manager';
import Offer from 'application/blocks/offer';

import Layout from 'ui/layout';
import Collapse from 'ui/collapse';
import Space from 'ui/space';
import Video from 'ui/video';
import Modal from 'ui/modal';
import Fancybox from 'ui/fancybox';
import {Button} from "antd";
import { getOrders, updateRecommendation } from 'services/orders';
import {AxiosResponse} from "axios";
import {
    orderSet, orderUpdated,
} from 'store/modules/order/action-creators';
import cn from "classnames";
import CalendarList from 'application/blocks/calendar-list';
import useAuth from "auth/useAuth";
import { client } from 'store/stomp-middleware';

SwiperCore.use([Pagination, EffectFade, Navigation]);

enum Statuses {default = 1, processing, error, success}

export const MainPage = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const order: IOrderState = useSelector(
        (state: IRootReducer) => state.order,
    );

    const isMobile = useMediaQuery({query: '(max-width: 768px)'});
    const gap = isMobile ? 20 : 30;
    const videoHeight = isMobile ? 190 : 442;

    const [isModalCancelVisible, setModalCancelVisible] = useState<boolean>(false);
    const [isCalendarModalVisible, setCalendarModalVisible] = useState<boolean>(false);
    const [recommendationToCancel, setRecommendationToCancel] = useState<IRecommendation | undefined>();
    const [recommendationToCalendar, setRecommendationToCalendar] = useState<IRecommendation | undefined>();
    const auth = useAuth();

    useEffect(() => {
        client.subscribe('/notification/landing', (message: any) => {
            const notification = JSON.parse(message.body);
            if (notification.eventType === 'OrderUpdated') {
                fetchData();
            }
        });
    }, []);

    const fetchData = () => {
        getOrders(auth.code).then((response: AxiosResponse) => {
            dispatch(orderSet(response.data));
        });
    }

    const onModalClose = () => {
        setCalendarModalVisible(false);
    };

    const onCancelBtn= (recommendationItem: IRecommendation) => {
        setModalCancelVisible(true);
        setRecommendationToCancel(recommendationItem);
    };

    const onAddCalendarBtn = (recommendationItem: IRecommendation) => {
        setRecommendationToCalendar(recommendationItem);
        setCalendarModalVisible(true);
    };

    const setOrder = React.useCallback(
        (order: IOrderState) => dispatch(orderSet(order)),
        [dispatch],
    );

    const handleCancelRecommendation = (item: IRecommendation) => {
        setModalCancelVisible(false);
        sendRecommendation(item,3);
    };

    const sendRecommendation = (item: IRecommendation, statusId: number, planDate: string = '') => {
        const code = auth.code;

        const data = {
            recommendationDTO: {
                ...item,
                planDate,
                statusId
            },
            code
        }

        updateRecommendation(data).then((response: AxiosResponse) => {
            setOrder(response.data);
        })
    }

    const onStartWatchVideo = (fragmentId: number): void => {
        dispatch({
            type: 'WATCH_STARTED',
            payload: {
                orderId: order.orderId,
                fragmentId: fragmentId,
                notType: 'WatchStarted',
            },
        });
    };

    const onEndWatchVideo = (fragmentId: number): void => {
        dispatch({
            type: 'WATCH_FINISHED',
            payload: {
                orderId: order.orderId,
                fragmentId: fragmentId,
                notType: 'WatchFinished',
            },
        });
    };

    useEffect(() => {
        let firstFragmentId: number = 0;

        order.recommendations.every((recommendation) =>
            recommendation.fragments.every((fragment) => {
                if (fragment.fragmentId) {
                    firstFragmentId = fragment.fragmentId;
                    return false;
                }
                return true;
            }));

        dispatch({
            type: 'LANDING_OPENED',
            payload: {
                orderId: order.orderId,
                fragmentId: firstFragmentId,
                notType: 'LandingOpened',
            },
        });
    }, [dispatch, order.orderId, order.recommendations]);

    return (
        <Space gap={60}>
            <Layout.Content style={{marginTop: 160}}>
                <Space>
                    <Layout.Section>
                        <OrderInfo
                            number={order.orderNumber}
                            auto={order.vehicleModel}
                            // vinCode={ '43536DFGTEC' }
                            code={order.vehicleNumber}
                            status={order.orderStatus}
                        />
                    </Layout.Section>

                    <Layout.Section title={'Рекомендации по обслуживанию'} padding={0}>
                        <Collapse defaultActiveKeys={1}>
                            {order.recommendations.filter(item => !!item.fragments.length).map((item: IStagedFragments, index: number) => (
                                <Collapse.Panel title={item.stageName} key={index + 1}>
                                    <Space gap={gap}>
                                        {item.fragments.map((item, index) => (
                                            <Space gap={gap} key={index}>
                                                {item.mediaType === 'VIDEO' ? (
                                                    <Video
                                                        width={'100%'}
                                                        height={videoHeight}
                                                        src={item.mediaLink}
                                                        onPlay={() => onStartWatchVideo(item.fragmentId)}
                                                        onEnded={() => onEndWatchVideo(item.fragmentId)}
                                                    />
                                                ) : (
                                                    <Fancybox>
                                                        <a data-fancybox="gallery" href={item.mediaLink}>
                                                            <img
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    objectPosition: 'center'
                                                                }}
                                                                width={'100%'}
                                                                alt={'Фрагмент'}
                                                                src={item.mediaLink}
                                                            />
                                                        </a>
                                                    </Fancybox>
                                                ) }

                                                <Space gap={gap} divider={true}>
                                                    {item.recommendations.map((item, index) => (
                                                        <Recommendation
                                                            item={item}
                                                            onCancelBtn={onCancelBtn}
                                                            sendRecommendation={sendRecommendation}
                                                            key={item.id}
                                                            status={Statuses[item.statusId]}
                                                            withDate={item.statusId === 2 && !item.changedByClient && order.orderStatus !== 4}
                                                        >
                                                            {item.statusId === 4 && !item.changedByClient && order.orderStatus !== 4 && (
                                                                <>
                                                                    <div
                                                                        className={cn('recommendation-status', 'today', 'new')}>
                                                                        <div className={cn('recommendation-info')}>
                                                                            <div className={cn('status-title')}>
                                                                                Дата исполнения
                                                                            </div>
                                                                            <div className={cn('plan-date')}>
                                                                                СЕГОДНЯ
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={cn('recommendation-buttons')}>
                                                                        <Button onClick={() => {
                                                                            onCancelBtn(item)
                                                                        }}>
                                                                            <img alt={"Отказаться"}
                                                                                 src={"/images/icons/CancelGray.svg"}/>
                                                                            <div>Отказаться</div>
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => sendRecommendation(item, 4)}>
                                                                            <img alt={"Подвердить"}
                                                                                 src={"/images/icons/ApproveGreen.svg"}/>
                                                                            <div>Подтвердить</div>
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {item.statusId === 2 && item.changedByClient && (
                                                                <>
                                                                    <div
                                                                        className={cn('recommendation-status', 'planned')}>
                                                                        <div className={cn('recommendation-info')}>
                                                                            <div className={cn('status-title')}>
                                                                                Дата исполнения
                                                                            </div>
                                                                            <div className={cn('plan-date')}>
                                                                                {item.planDate.toString().split('T')[0].split('-').reverse().join('.')}
                                                                            </div>
                                                                        </div>
                                                                        <Button type="text"
                                                                                className={cn('btn-to-calendar')}
                                                                                onClick={() => onAddCalendarBtn(item)}>
                                                                            Добавить в <br/>календарь
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {item.statusId === 3 && (
                                                                <>
                                                                    <div
                                                                        className={cn('recommendation-status', 'declined')}>
                                                                        <div className={cn('recommendation-info')}>
                                                                            <div className={cn('status-title')}>
                                                                                Рекомендация
                                                                            </div>
                                                                            <div className={cn('plan-date')}>
                                                                                ОТКЛОНЕНА
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {item.statusId === 4 && item.changedByClient && (
                                                                <>
                                                                    <div
                                                                        className={cn('recommendation-status', 'today')}>
                                                                        <div className={cn('recommendation-info')}>
                                                                            <div className={cn('status-title')}>
                                                                                Дата исполнения
                                                                            </div>
                                                                            <div className={cn('plan-date')}>
                                                                                СЕГОДНЯ
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Recommendation>
                                                    ))}
                                                </Space>
                                            </Space>
                                        ))}
                                    </Space>
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </Layout.Section>

                    <Layout.Section title={'Ваш сервис-менеджер'}>
                        <Manager
                            name={order.managerName}
                            phone={order.managerContact}
                            avatar={order.managerPhoto}
                        />
                    </Layout.Section>
                </Space>
            </Layout.Content>


            {false && (
                <Layout.Section title={'Предложения для Вас'} padding={0}>
                    {order.promotions.length > 1 ? (
                        <Swiper
                            pagination={{el: '.offer-pagination'}}
                            navigation
                        >
                            {order.promotions.map((promotion) => (
                                <SwiperSlide key={promotion.id}>
                                    <Offer
                                        title={promotion.name}
                                        description={promotion.description}
                                        link={promotion.link}
                                        image={promotion.smallImageURL}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <Offer
                            title={order.promotions[0].name}
                            description={order.promotions[0].description}
                            link={order.promotions[0].link}
                            image={order.promotions[0].imageURL}
                        />
                    )}
                    <div className={'offer-pagination'}/>
                </Layout.Section>
            ) }
            <Modal
                title={'Отказаться от рекоммендации?'}
                src={"/images/icons/mail.svg"}
                isVisible={isModalCancelVisible}
                onOk={() => {
                    if (recommendationToCancel) handleCancelRecommendation(recommendationToCancel)
                }}
                onClose={() => setModalCancelVisible(false)}
                withOptions={true}>
            </Modal>
            <Modal
                onClose={onModalClose}
                isVisible={isCalendarModalVisible}
            >
                {recommendationToCalendar &&
                    <CalendarList
                        afterCalendarClick={() => setCalendarModalVisible(false)}
                        recommendation={recommendationToCalendar}
                    />
                }
            </Modal>
        </Space>
    );
};
